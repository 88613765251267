import {Component, Input} from '@angular/core';
import {isDark$} from '@src/module/utility/color-scheme';

@Component({
  standalone: true,
  selector: 'utility-logo-component',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="viewBox"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5_9998)">
        <path
          d="M25.4382 7.56916L20.2281 12.7741C20.1576 12.8457 20.0702 12.8984 19.974 12.9273C19.8777 12.9562 19.7757 12.9604 19.6775 12.9395C19.2636 12.8593 18.8428 12.82 18.4212 12.8222C16.9021 12.8194 15.4285 13.3412 14.2496 14.2993C14.2057 14.336 14.1522 14.3594 14.0955 14.3667C14.0388 14.3741 13.9811 14.365 13.9293 14.3407C13.8775 14.3164 13.8338 14.2779 13.8032 14.2295C13.7725 14.1812 13.7564 14.1252 13.7565 14.068V6.09939C13.7558 5.96983 13.7966 5.84346 13.8729 5.73873C13.9492 5.63401 14.057 5.55643 14.1805 5.51735C17.8793 4.39518 21.8722 4.78479 25.2844 6.60083C25.3692 6.64455 25.4426 6.70764 25.4986 6.78498C25.5545 6.86233 25.5915 6.95176 25.6065 7.04603C25.6214 7.14031 25.614 7.2368 25.5848 7.32768C25.5556 7.41856 25.5054 7.50128 25.4382 7.56916Z"
          fill="#F2994A"
        />
        <path
          d="M32.8908 21.2416C32.1099 27.8064 26.8537 33.1024 20.292 33.9179C16.8227 34.3653 13.3081 33.5493 10.3909 31.6188C7.47363 29.6884 5.34855 26.7726 4.40407 23.4044C4.03981 22.122 3.85623 20.7951 3.85867 19.462V0.356051C3.85867 0.195297 3.92253 0.0411272 4.0362 -0.0725429C4.14987 -0.186213 4.30404 -0.250072 4.46479 -0.250072H4.67416C6.49748 -0.250174 8.25134 0.449375 9.57407 1.70432C10.8968 2.95927 11.6876 4.67394 11.7833 6.49475V6.50626C11.7885 6.62351 11.7937 6.74704 11.7937 6.86952V19.462C11.7942 20.3509 11.9731 21.2306 12.32 22.049C12.6668 22.8674 13.1746 23.6078 13.813 24.2262C14.4515 24.8446 15.2076 25.3285 16.0366 25.6492C16.8657 25.9698 17.7506 26.1206 18.6391 26.0927C22.0151 25.988 24.8469 23.2484 25.0531 19.8766C25.1157 18.8934 24.9576 17.9087 24.5904 16.9946C24.543 16.883 24.5296 16.7598 24.552 16.6406C24.5743 16.5214 24.6314 16.4115 24.716 16.3246L29.6707 11.3647C29.7354 11.3009 29.8135 11.2523 29.8993 11.2225C29.9851 11.1928 30.0765 11.1825 30.1667 11.1926C30.257 11.2026 30.3439 11.2326 30.4211 11.2805C30.4983 11.3284 30.5638 11.3929 30.6129 11.4694C32.5051 14.3544 33.3123 17.8173 32.8908 21.2416Z"
          [attr.fill]="commonColor"
        />
        <path
          d="M22.0947 19.9386H18.0487C17.9288 19.9384 17.8118 19.9027 17.7122 19.836C17.6126 19.7693 17.535 19.6747 17.4891 19.564C17.4432 19.4533 17.4311 19.3315 17.4543 19.2139C17.4775 19.0963 17.535 18.9883 17.6195 18.9033L25.8508 10.6751C25.9071 10.6188 25.9739 10.5741 26.0475 10.5437C26.1211 10.5132 26.1999 10.4975 26.2795 10.4975C26.3591 10.4975 26.4379 10.5132 26.5115 10.5437C26.5851 10.5741 26.6519 10.6188 26.7082 10.6751C26.7645 10.7314 26.8091 10.7982 26.8396 10.8718C26.8701 10.9453 26.8857 11.0242 26.8857 11.1038C26.8857 11.1834 26.8701 11.2622 26.8396 11.3358C26.8091 11.4093 26.7645 11.4762 26.7082 11.5325L19.5153 18.7253H22.0968C22.1786 18.7219 22.2602 18.7351 22.3368 18.7641C22.4134 18.793 22.4833 18.8372 22.5424 18.8938C22.6014 18.9505 22.6484 19.0185 22.6805 19.0938C22.7127 19.1691 22.7292 19.2501 22.7292 19.332C22.7292 19.4138 22.7127 19.4948 22.6805 19.5701C22.6484 19.6454 22.6014 19.7135 22.5424 19.7701C22.4833 19.8268 22.4134 19.8709 22.3368 19.8999C22.2602 19.9288 22.1786 19.942 22.0968 19.9386H22.0947Z"
          [attr.fill]="commonColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_9998">
          <rect width="36" height="36" [attr.fill]="commonColor" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class LogoComponent {
  public commonColor: 'white' | 'black' = 'white';

  @Input()
  public width = 36;

  @Input()
  public height = 36;

  public get viewBox(): string {
    return `0 0 36 36`;
  }

  constructor() {
    isDark$.subscribe((isDark: boolean) => {
      this.commonColor = isDark ? 'white' : 'black';
    });
  }
}
